<template>
  <div>
    <transition
      name="customFade"
      enter-active-class="fadeIn"
      leave-active-class="fadeOut"
    >
      <div
        v-if="visible && order.attributes"
        style="animation-duration: 0.3s"
        class="fixed inset-0 z-10 mx-4 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
            v-on:click="handleClose"
          ></div>
          <span
            class="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:align-middle"
          >
            <div class="mb-6 flex items-center justify-between">
              <div class="flex items-center space-x-4">
                <div>
                  <h3 class="text-lg font-bold leading-6 text-gray-900">
                    {{ title }}
                  </h3>
                  <p class="mt-1 max-w-2xl text-sm text-gray-500">
                    {{ description }}
                  </p>
                </div>
              </div>
              <div>
                <span
                  v-if="order.attributes.is_completed"
                  class="inline-flex w-24 items-center justify-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                >
                  Selesai
                </span>
                <span
                  v-else
                  class="inline-flex w-36 items-center justify-center rounded-full bg-red-100 px-3 py-1 text-center text-sm font-medium text-red-800"
                >
                  Belum Selesai
                </span>
              </div>
            </div>
            <div class="border-t border-gray-200 pt-6">
              <slot name="detail" :order="order">
                <dl class="flex flex-row gap-x-4">
                  <div class="w-full">
                    <dt class="text-xs text-gray-700">No PO</dt>
                    <dd class="mt-1 text-sm font-bold text-gray-900">
                      {{ order.attributes.destination_code }} /
                      {{ order.attributes.origin_code }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-xs text-gray-700">Dibuat Tanggal</dt>
                    <dd class="mt-1 text-sm font-bold text-gray-900">
                      {{ dayjs(order.attributes.createdAt).format('ll LT') }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-xs text-gray-700">Kode Kantor</dt>
                    <dd class="mt-1 text-sm font-bold text-gray-900">
                      {{
                        order.relationships['destination-office'].data
                          ? getRelationships(
                              getOrder,
                              order.relationships['destination-office'].data.id
                            )?.attributes.code
                          : '-'
                      }}
                    </dd>
                  </div>
                </dl>
                <dl class="mt-6 flex flex-row gap-x-4">
                  <div class="w-full">
                    <dt class="text-xs text-gray-700">Dibuat Oleh</dt>
                    <dd class="mt-1 text-sm font-bold text-gray-900">
                      {{ order.attributes.created_by?.office?.name }}/{{
                        order.attributes.created_by?.user?.name
                      }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-xs text-gray-700">Wilayah Area</dt>
                    <dd class="mt-1 text-sm font-bold text-gray-900">
                      {{ order.attributes.area_name }} ({{
                        order.attributes.area_code
                      }})
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-xs text-gray-700">Diambil/Dikirim</dt>
                    <dd class="mt-1 text-sm font-bold text-gray-900">
                      {{
                        order.attributes.order_shipment == 'pickup'
                          ? 'Diambil'
                          : 'Dikirim'
                      }}
                    </dd>
                  </div>
                </dl>
                <dl class="mt-6 flex flex-row gap-x-4">
                  <div class="w-full">
                    <dt class="text-xs text-gray-700">Nama</dt>
                    <dd class="mt-1 text-sm font-bold text-gray-900">
                      {{
                        getOrder.data.relationships['destination-office']?.data
                          ? getRelationships(
                              getOrder,
                              getOrder.data.relationships['destination-office']
                                .data.id
                            )?.attributes.name ?? '-'
                          : ''
                      }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-xs text-gray-700">Alamat Kantor 1</dt>
                    <dd class="mt-1 text-sm font-bold text-gray-900">
                      {{
                        getOrder.data.relationships['destination-office']?.data
                          ? getRelationships(
                              getOrder,
                              getOrder.data.relationships['destination-office']
                                .data.id
                            )?.attributes.address ?? '-'
                          : ''
                      }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-xs text-gray-700">Alamat Kantor 2</dt>
                    <dd class="mt-1 text-sm font-bold text-gray-900">
                      {{
                        getOrder.data.relationships['destination-office']?.data
                          ? getRelationships(
                              getOrder,
                              getOrder.data.relationships['destination-office']
                                .data.id
                            )?.attributes.address2 ?? '-'
                          : ''
                      }}
                    </dd>
                  </div>
                </dl>
                <dl class="mt-6 flex flex-row gap-x-4">
                  <div class="w-full truncate">
                    <dt class="text-xs text-gray-700">Diupdate Oleh</dt>
                    <dd class="mt-1 text-sm font-bold text-gray-900">
                      {{ order.attributes.latest_update_by }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-xs text-gray-700">Diupdate Pada</dt>
                    <dd class="mt-1 text-sm font-bold text-gray-900">
                      {{ dayjs(order.attributes.updatedAt).format('ll LT') }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-xs text-gray-700">
                      Kode Gudang/Gudang Tujuan
                    </dt>
                    <dd class="mt-1 text-sm font-bold text-gray-900">
                      {{
                        order.relationships['origin-warehouse']?.data
                          ? getRelationships(
                              getOrder,
                              order.relationships['origin-warehouse'].data.id
                            )?.attributes.code
                          : '-'
                      }}/{{
                        order.relationships['destination-warehouse']?.data
                          ? getRelationships(
                              getOrder,
                              order.relationships['destination-warehouse'].data
                                .id
                            )?.attributes.code
                          : '-'
                      }}
                    </dd>
                  </div>
                </dl>
                <dl class="mt-6 flex flex-row gap-x-4">
                  <div class="w-full">
                    <dt class="text-xs text-gray-700">Nomor Telpon</dt>
                    <dd class="mt-1 text-sm font-bold text-gray-900">
                      {{
                        getOrder.data.relationships['destination-office']?.data
                          ? getRelationships(
                              getOrder,
                              getOrder.data.relationships['destination-office']
                                .data.id
                            )?.attributes.phone ?? '-'
                          : ''
                      }}
                    </dd>
                  </div>
                </dl>
                <dl
                  v-if="order.attributes.order_shipment == 'delivery'"
                  class="mt-6 flex flex-row gap-x-4"
                >
                  <div class="w-full truncate">
                    <dt class="text-xs text-gray-700">Dikirim ke Alamat</dt>
                    <dd class="mt-1 text-sm font-bold text-gray-900">
                      {{ order.attributes.shipment_address }},
                      {{
                        order.relationships['village']?.data
                          ? getRelationships(
                              getOrder,
                              order.relationships['village'].data.id
                            )?.attributes.name
                          : '-'
                      }},
                      {{
                        order.relationships['district']?.data
                          ? getRelationships(
                              getOrder,
                              order.relationships['district'].data.id
                            )?.attributes.name
                          : '-'
                      }},
                      {{
                        order.relationships['city']?.data
                          ? getRelationships(
                              getOrder,
                              order.relationships['city'].data.id
                            )?.attributes.name
                          : '-'
                      }},
                      {{
                        order.relationships['province']?.data
                          ? getRelationships(
                              getOrder,
                              order.relationships['province'].data.id
                            )?.attributes.name
                          : '-'
                      }}
                    </dd>
                  </div>
                </dl>
              </slot>
              <div class="my-6 sm:col-span-6" v-if="withOrderDetails">
                <slot
                  name="order-details"
                  :order-details="
                    getOrder.included?.filter(
                      (it) => it.type === 'order-details'
                    )
                  "
                  :order="order"
                >
                  <Datatable :paginated="false" :footer="false" class="mt-4">
                    <template v-slot:thead>
                      <thead :class="accent">
                        <tr>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            KODE BARANG
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            JUMLAH
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            BERAT
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            TOTAL HARGA
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            PV
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            BV
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            TOTAL BERAT
                          </th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:tbody>
                      <tbody>
                        <tr
                          class="bg-white"
                          v-for="(barang, index) in getOrder.included?.filter(
                            (it) => it.type === 'order-details'
                          )"
                          :key="index"
                        >
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                          >
                            {{ barang.attributes.product_code }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                          >
                            {{ barang.attributes.product_qty | toCurrency }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                          >
                            {{ barang.attributes.product_weight }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                          >
                            {{ barang.attributes.total_price | toCurrency }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                          >
                            {{ barang.attributes.point_value || 0 }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                          >
                            {{ barang.attributes.bonus_value || 0 }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                          >
                            {{ barang.attributes.total_weight }}
                          </td>
                        </tr>
                      </tbody>
                      <!-- Informasi Total -->
                      <tfoot>
                        <tr class="bg-white">
                          <td
                            colspan="3"
                            class="whitespace-nowrap px-6 py-3 pr-12 text-right text-sm text-gray-500"
                          >
                            Total
                          </td>

                          <td
                            class="whitespace-nowrap px-6 py-3 pr-12 text-right text-sm text-gray-500"
                          >
                            {{
                              order.attributes.grand_total_price | toCurrency
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-3 pr-12 text-right text-sm text-gray-500"
                          >
                            {{ order.attributes.total_point_value }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-3 pr-12 text-right text-sm text-gray-500"
                          >
                            {{
                              order.attributes.total_bonus_value | toCurrency
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-3 pr-12 text-right text-sm text-gray-500"
                          >
                            {{ order.attributes.grand_total_weight }}
                          </td>
                        </tr>
                        <tr class="bg-white">
                          <td
                            colspan="3"
                            class="whitespace-nowrap px-6 pb-3 pr-12 text-right text-sm text-gray-500"
                          >
                            Total Pembayaran
                          </td>

                          <td
                            class="whitespace-nowrap px-6 pb-3 pr-12 text-right text-sm text-gray-500"
                          >
                            {{ order.attributes.payment_amount | toCurrency }}
                          </td>
                        </tr>
                        <tr class="bg-white">
                          <td
                            colspan="3"
                            class="whitespace-nowrap px-6 pb-3 pr-12 text-right text-sm text-gray-500"
                          >
                            Total Kekurangan
                          </td>

                          <td
                            class="whitespace-nowrap px-6 pb-3 pr-12 text-right text-sm text-gray-500"
                          >
                            {{
                              Math.max(
                                order.attributes.grand_total_price -
                                  order.attributes.payment_amount,
                                0
                              ) | toCurrency
                            }}
                          </td>
                        </tr>
                      </tfoot>
                    </template>
                  </Datatable>
                </slot>
              </div>
              <!-- bonus -->
              <div class="mt-6 mb-6 border-t pt-6 space-y-4" v-if="withBonus && !loadingOrder">
                <div class="flex gap-x-2">
                  <h3 class="text-lg font-bold leading-6 text-gray-900">
                    Daftar Bonus
                  </h3>
                </div>
                <order-detail-bonus-table />
              </div>
              <!-- table invoice -->
              <div class="mt-6 mb-6 border-t pt-6" v-if="withPayments">
                <div class="flex gap-x-2">
                  <h3 class="text-lg font-bold leading-6 text-gray-900">
                    Daftar Faktur Pembayaran
                  </h3>
                  <div
                    v-if="!order.attributes.has_payment_methods"
                    class="tooltip"
                  >
                    <p class="tooltiptext">
                      Faktur ini belum memiliki metode pembayaran
                    </p>
                    <svg
                      title="Faktur ini belum memiliki metode pembayaran"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      class="mx-auto h-6 w-6 text-yellow-500"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
                <Datatable :paginated="false" :footer="false" class="mt-4">
                  <template v-slot:thead>
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500"
                        >
                          No. Pembayaran
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500"
                        >
                          Kode Gudang
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500"
                        >
                          Tanggal
                        </th>
                        <template>
                          <th
                            scope="col"
                            class="px-6 py-3 text-right text-xs font-medium tracking-wider text-gray-500"
                          >
                            Total
                          </th>
                        </template>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500"
                          colspan="2"
                        ></th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:tbody>
                    <tbody
                      v-if="order.relationships.payments?.data?.length > 0"
                    >
                      <template
                        v-for="(invoice, index) in order.relationships.payments
                          .data"
                      >
                        <payment-row
                          :key="index"
                          :isRedirectToDetail="false"
                          :order="order"
                          :payment="getRelationships(getOrder, invoice.id)"
                          :collapsed="
                            paymentAccordion.active !==
                            getRelationships(getOrder, invoice.id)
                          "
                          :any-active-row="paymentAccordion.active !== null"
                          v-on:toggle="togglePaymentAccordion(invoice.id)"
                        />
                        <template
                          v-if="
                            paymentAccordion.active === invoice.id &&
                            !getLoadingPaymentMethods
                          "
                        >
                          <payment-detail-row
                            v-for="(paymentMethod, index) in paymentMethods"
                            :deletable="deleteablePaymentMethod"
                            :key="`payment-methods-${index}`"
                            :payment-method="paymentMethod"
                            :included="getPaymentPaymentMethods.included"
                            :invoice="getRelationships(getOrder, invoice.id)"
                          />
                        </template>
                      </template>
                    </tbody>
                    <tbody v-else>
                      <tr class="bg-white">
                        <td
                          class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                          colspan="3"
                        >
                          Belum ada riwayat transaksi
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </Datatable>
              </div>
              <!-- Daftar Pengiriman -->
              <div class="mb-6" v-if="withStockMovement">
                <h3 class="text-lg font-bold leading-6 text-gray-900">
                  Daftar Pengiriman
                </h3>
                <Datatable :paginated="false" :footer="false" class="mt-4">
                  <template v-slot:thead>
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          KODE KEMASAN
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Tanggal
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          TOTAL BARANG
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          STATUS
                        </th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:tbody>
                    <tbody v-if="!getStockMovements.data.length">
                      <tr class="bg-white">
                        <td
                          class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                        >
                          Belum ada pengiriman
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr
                        class="cursor-pointer bg-white hover:bg-green-100"
                        v-for="(stockMovement, index) in getStockMovements.data"
                        :key="index"
                        v-on:click="handleClickStockMovement(stockMovement)"
                      >
                        <td
                          class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                        >
                          {{ stockMovement.attributes.code }}
                        </td>
                        <td
                          class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                        >
                          {{ stockMovement.attributes.createdAt | formatDate }}
                        </td>
                        <td
                          class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
                        >
                          {{
                            Object.keys(
                              stockMovement.attributes.product_summaries
                            ).length
                          }}
                        </td>
                        <td
                          class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                        >
                          <span
                            v-if="stockMovement.attributes.is_received"
                            class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                          >
                            Diterima
                          </span>
                          <span
                            v-else
                            class="inline-flex items-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-800"
                          >
                            Belum diterima
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </Datatable>
              </div>
            </div>
            <!-- aksi -->
            <slot v-if="customAction" name="action" :order="order" />
            <div
              v-else
              class="flex"
              :class="[
                order.attributes.deletedAt ? 'justify-end' : 'justify-between',
              ]"
            >
              <div class="flex gap-x-2" v-if="!order.attributes.deletedAt">
                <button
                  @click="handleOpenPrevOrder"
                  class="bg-white-600 inline-flex items-center space-x-2 rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
                  v-if="order.attributes.previous_order_id"
                  title="Lihat Faktur Awal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-6 w-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                    />
                  </svg>
                  <span class="hidden md:block">Lihat Faktur Awal</span>
                </button>
                <button
                  v-if="!order.attributes.is_valid_for_payment && deleteable"
                  @click="handleDelete"
                  type="button"
                  title="Hapus Faktur"
                  class="bg-white-600 inline-flex items-center rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 md:mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                  <span class="hidden md:block">Hapus</span>
                </button>
                <button
                  v-if="!order.attributes.is_valid_for_payment && editable"
                  @click="handleEdit"
                  title="Faktur Ulang"
                  class="bg-white-600 inline-flex items-center rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 md:mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                    />
                  </svg>
                  <span class="hidden md:block">{{
                    order.attributes.canceled_from ? 'Faktur Ulang' : 'Edit'
                  }}</span>
                </button>
                <button
                  @click="handlePrint"
                  title="Cetak Faktur"
                  class="bg-white-600 inline-flex items-center rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-6 w-6 md:mr-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
                    />
                  </svg>
                  <span class="hidden md:block">Cetak</span>
                </button>
                <button
                  @click="handleDownload"
                  title="Download Faktur"
                  class="bg-white-600 inline-flex items-center rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-6 w-6 md:mr-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                    />
                  </svg>
                  <span class="hidden md:block">Download</span>
                </button>
              </div>
              <div class="flex justify-end">
                <button
                  v-if="canValidate"
                  @click="handleValidate"
                  class="focus:outline-none mr-2 inline-flex items-center rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-2 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                    />
                  </svg>
                  Validasi
                </button>
                <button
                  @click="handleClose"
                  type="button"
                  class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
                >
                  Tutup
                </button>
              </div>
            </div>
          </div>
        </div>
        <vue-html2pdf
          v-if="printable"
          :show-layout="false"
          :float-layout="true"
          :preview-modal="print.preview"
          :enable-download="print.download"
          :paginate-elements-by-height="5000"
          :filename="filename"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="210mm"
          ref="html2Pdf"
        >
          <PdfContent
            slot="pdf-content"
            :order="getOrder"
            :order_details="
              getOrder?.data?.relationships?.['order-details']?.data?.map(
                (it) => getRelationships(getOrder, it.id)
              )
            "
          />
        </vue-html2pdf>
      </div>
    </transition>

    <modal-delete-confirmation
      :visible="visibleDeleteModal"
      @close="() => (visibleDeleteModal = false)"
      @delete="handleConfirmDelete"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Datatable from '@/components/base/Datatable';
import PaymentRow from '@/components/kasir/detail-penjualan/PaymentRow.vue';
import PaymentDetailRow from '@/components/kasir/detail-penjualan/PaymentDetailRow.vue';
import PdfContent from '@/layouts/LayoutPrintA4.vue';
import modalDeleteConfirmation from '@/components/penjualan/modal-delete-confirmation.vue';
import VueHtml2pdf from 'vue-html2pdf';
import { StorageService } from '@/services/storage.service';
import OrderDetailBonusTable from './OrderDetailBonusTable.vue';

export default {
  name: 'OrderDetailModal',
  components: {
    Datatable,
    PaymentRow,
    PaymentDetailRow,
    VueHtml2pdf,
    PdfContent,
    modalDeleteConfirmation,
    OrderDetailBonusTable
  },
  props: {
    title: {
      type: String,
      default: 'Detail Penjualan',
    },
    description: {
      type: String,
      default: 'Data Faktur Penjualan',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      required: true,
    },
    editable: {
      // true untuk memunculkan edit button
      type: Boolean,
      default: true,
    },
    deleteable: {
      // true untuk memunculkan delete button
      type: Boolean,
      default: true,
    },
    validateable: {
      // true untuk memunculkan validasi button
      type: Boolean,
      default: true,
    },
    printable: {
      // true untuk memunculkan print button
      type: Boolean,
      default: true,
    },
    withPayments: {
      // true untuk memunculkan payments table
      type: Boolean,
      default: true,
    },
    withBonus: {
      // true untuk memunculkan payments table
      type: Boolean,
      default: false,
    },
    withOrderDetails: {
      // true untuk memunculkan barang table
      type: Boolean,
      default: true,
    },
    withDeleteConfirmation: {
      // true untuk memunculkan konfirmasi saat hapus
      type: Boolean,
      default: false,
    },
    customAction: {
      type: Boolean,
      default: false,
    },
    validationIgnorePaymentStatus: {
      // true untuk memunculkan tombol validasi mengignore status payment
      type: Boolean,
      default: false,
    },
    accent: {
      type: String,
      default: 'bg-gray-50',
    },
    withStockMovement: {
      type: Boolean,
      default: false,
    },
    deleteablePaymentMethod: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'close',
    'refresh',
    'edit',
    'delete',
    'open-prev-order',
    'detail-stock-movement',
  ],
  data() {
    return {
      paymentAccordion: {
        active: null,
      },
      print: {
        preview: false,
        download: false,
      },
      visibleDeleteModal: false,
      confirmationSentence: '',
    };
  },
  computed: {
    ...mapGetters({
      getOrder: 'orders/getOrder',
      isLoading: 'orders/getLoading',
      getPaymentPaymentMethods: 'payments/getPaymentMethods',
      getLoadingPaymentMethods: 'payments/getLoadingPaymentMethods',
      getStockMovements: 'stock_movements/getStockMovements',
      loadingOrder: 'orders/getLoading'
    }),
    paymentMethods() {
      return this.getPaymentPaymentMethods.data.filter(
        (item) => item.attributes.is_used
      );
    },
    validationSentence() {
      return `Faktur dengan kode ${this.getOrder.data.attributes.origin_code} akan saya hapus`;
    },
    canEdit() {
      return this.editable && !this.order.attributes.is_valid_for_payment;
    },
    canValidate: function () {
      if (!this.validateable) {
        return false;
      }

      if (
        !this.validationIgnorePaymentStatus &&
        !this.order.attributes.has_payment_methods
      ) {
        return false;
      }

      return (
        !this.order.attributes.is_valid_for_payment &&
        !this.order.attributes.deletedAt &&
        this.order.attributes.created_by?.office?.id ===
          StorageService.getUser().office_id
      );
    },
    filename() {
      const order = this.getOrder;
      return `${order.data.attributes.origin_code}_${
        order.data.attributes.destination_code
      }_${dayjs(order.data.attributes.createdAt).format('L LTS')}`;
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      updateOrder: 'orders/updateOrder',
      fetchPaymentPaymentMethods: 'payments/fetchPaymentPaymentMethods',
      removeOrder: 'orders/deleteOrder',
      validate: 'orders/validate',
    }),
    getRelationships(resource, id) {
      let data = resource.included?.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    handleClose: function () {
      this.$emit('close');
    },
    handleClickStockMovement(item) {
      this.$emit('detail-stock-movement', item.id);
    },
    togglePaymentAccordion(id) {
      this.paymentAccordion.active =
        this.paymentAccordion.active === id ? null : id;

      if (this.paymentAccordion.active === id) {
        this.fetchPaymentPaymentMethods({
          id,
          include: 'office-bank',
          fields: {
            'payment-methods':
              'payment_amount,office-bank,balance_used,is_used,is_verified',
            'office-banks': 'bank_name',
          },
        });
      }
    },

    handleValidate: function () {
      this.validate({ id: this.order.id }).then((response) => {
        if (response) {
          this.$emit('close');
          this.$emit('refresh');
        }
      });
    },

    handleEdit: function () {
      this.$emit('edit', this.order.id);
    },

    handlePrint: function () {
      this.print.preview = true;
      this.print.download = false;
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
      }, 0);
    },

    handleDownload: function () {
      this.print.preview = false;
      this.print.download = true;
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
      }, 0);
    },

    handleDelete: function () {
      if (this.withDeleteConfirmation) {
        this.visibleDeleteModal = true;
      } else {
        this.$emit('delete');
      }
    },

    handleConfirmDelete() {
      this.removeOrder(this.getOrder.data.id).then((response) => {
        this.visibleDeleteModal = !this.visibleDeleteModal;
        if (response) {
          this.$emit('refresh');
        }

        this.handleClose();
      });
    },

    handleOpenPrevOrder: function () {
      this.$emit('open-prev-order');
    },
  },
};
</script>
